import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

const Whatismyip = () => {
  // Client-side Runtime Data Fetching
  const [ip, setIp] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://api.ipify.org/?format=json`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setIp(resultData.ip)
      })
  }, [])
  return (
    <Layout>
      <Seo
        title="Cual es mi IP"
        description="Consulte su dirección IP pública real"
      />
      <Container className="text-center pb-6 pt-6">
        <h1>Su ip pública es:</h1>
        <h2>{ip}</h2>
        <p>
          Si su ip esta bloqueada, envíe un{" "}
          <Link
            to={`https://portal.puntohost.com/submitticket.php?step=2&deptid=2&subject=Mi%20IP%20esta%20bloqueada%20${ip}`}
          >
            ticket{" "}
          </Link>
          para verificar el motivo y ejecutar las acciones pertinentes.
        </p>
      </Container>
    </Layout>
  )
}
export default Whatismyip
